import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GreetingScreen.css';

const GreetingScreen = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handlePOSClick = () => {
    navigate('/pos');
  };

  return (
    <div className="greeting-page">
      <div className="welcome-text">
        <div className="animated-text">
          {/* Each letter wrapped in a span to apply individual animations */}
          {'Ujjal'.split('').map((letter, index) => (
            <span key={index} className="drop-letter" style={{ animationDelay: `${index * 0.3}s` }}>
              {letter}
            </span>
          ))}
        </div>
        <h2 className="drop-flour">Flour Mills</h2>
        <h3 className="drop-since">Since 1983</h3>
      </div>
      <div className="button-container">
        <div className="glass-button" onClick={handleLoginClick}>
          <span>Login</span>
        </div>
        <div className="glass-button" onClick={handlePOSClick}>
          <span>POS</span>
        </div>
      </div>
    </div>
  );
};

export default GreetingScreen;
