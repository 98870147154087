import React from 'react';

const CustomerDetails = ({ posData, handlePosChange }) => {
    return (
        <div className="form-section">
            <h3>Customer Details</h3>
            <form>
                <label>
                    Customer Name:
                    <input
                        type="text"
                        name="customerName"
                        value={posData.customerName}
                        onChange={handlePosChange}
                    />
                </label>
                <label>
                    Phone Number:
                    <input
                        type="text"
                        name="phoneNumber"
                        value={posData.phoneNumber}
                        onChange={handlePosChange}
                    />
                </label>
                <label>
                    Delivery Address:
                    <input
                        type="text"
                        name="deliveryAddress"
                        value={posData.deliveryAddress}
                        onChange={handlePosChange}
                    />
                </label>
            </form>
        </div>
    );
};

export default CustomerDetails;
