import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GreetingScreen from './GreetingScreen';
import LoginPage from './LoginPage';
import POSPage from './POSPage';
import AdminDashboard from './AdminDashboard'; // Ensure this is correctly imported

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GreetingScreen />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pos" element={<POSPage />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} /> {/* Ensure this route exists */}
      </Routes>
    </Router>
  );
}

export default App;
