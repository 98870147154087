// AdminDashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [activePopup, setActivePopup] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    business_name: '',
    address: '',
    region: '',
    contact: '',
    contact_2: '',
    previous_due: '',
    image: null,
  });
  const [productData, setProductData] = useState({
    productName: '',
    productType: '',
    remarks: '',
    image: null,
  });
  const [message, setMessage] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fetch customers from the database
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get('http://pos.ujjalflourmills.com/api/customers');
        setCustomers(response.data);
        setFilteredCustomers(response.data);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };
    fetchCustomers();
  }, []);

  // Fetch products from the database
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('http://pos.ujjalflourmills.com//api/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handlePopup = (index) => {
    setActivePopup(activePopup === index ? null : index);
    setMessage('');
    setSelectedCustomer(null);
    setSelectedProduct(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleProductFileChange = (e) => {
    setProductData((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      form.append(key, value);
    });

    try {
      const response = await axios.post('http://pos.ujjalflourmills.com//api/add-customer', form);
      if (response.data.success) {
        setMessage('Customer added successfully!');
        setFormData({
          name: '',
          business_name: '',
          address: '',
          region: '',
          contact: '',
          contact_2: '',
          previous_due: '',
          image: null,
        });
        const updatedCustomers = await axios.get('http://pos.ujjalflourmills.com//api/customers');
        setCustomers(updatedCustomers.data);
        setFilteredCustomers(updatedCustomers.data);
      } else {
        setMessage('Failed to add customer. Please try again.');
      }
    } catch (error) {
      console.error('Error saving customer:', error);
      setMessage('An error occurred while saving the customer.');
    }
  };

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    Object.entries(productData).forEach(([key, value]) => {
      form.append(key, value);
    });

    try {
      const response = await axios.post('http://pos.ujjalflourmills.com//api/add-product', form);
      if (response.data.success) {
        setMessage('Product added successfully!');
        setProductData({
          productName: '',
          productType: '',
          remarks: '',
          image: null,
        });
        const updatedProducts = await axios.get('http://pos.ujjalflourmills.com//api/products');
        setProducts(updatedProducts.data);
      } else {
        setMessage('Failed to add product. Please try again.');
      }
    } catch (error) {
      console.error('Error saving product:', error);
      setMessage('An error occurred while saving the product.');
    }
  };

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setActivePopup(11);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setActivePopup(12);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = customers.filter((customer) =>
      customer.id.toString().includes(query) ||
      customer.name.toLowerCase().includes(query) ||
      customer.region.toLowerCase().includes(query) ||
      customer.contact.includes(query)
    );
    setFilteredCustomers(filtered);
  };

  const handlePriceUpdate = async (e) => {
    e.preventDefault();
    const { id, price } = selectedProduct;

    try {
      const response = await axios.post('http://pos.ujjalflourmills.com//api/update-product', { id, price });
      if (response.data.success) {
        setMessage('Product price updated successfully!');
        const updatedProducts = await axios.get('http://pos.ujjalflourmills.com//api/products');
        setProducts(updatedProducts.data);
        setSelectedProduct(null);
        setActivePopup(4);
      } else {
        setMessage('Failed to update product price. Please try again.');
      }
    } catch (error) {
      console.error('Error updating product price:', error);
      setMessage('An error occurred while updating the product.');
    }
  };

  return (
    <div className="admin-dashboard" onClick={() => setActivePopup(null)}>
      <div className="buttons-container">
        {[...Array(10)].map((_, index) => (
          <div key={index} className="button-wrapper">
            <button
              className="glass-button"
              onClick={(e) => {
                e.stopPropagation();
                handlePopup(index + 1);
              }}
            >
              {index === 0 ? 'Add Customer' : 
               index === 1 ? 'View Customers' : 
               index === 2 ? 'Add Product & Details' : 
               index === 3 ? 'View Products' : 
               `Button ${index + 1}`}
            </button>
          </div>
        ))}
      </div>

      {activePopup && (
        <div className="popup-container" onClick={(e) => e.stopPropagation()}>
          {activePopup === 1 && (
            <div className="form-section">
              <h2 className="form-title">Add Customer Information</h2>
              <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
                <input type="text" name="business_name" placeholder="Business Name" value={formData.business_name} onChange={handleChange} />
                <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} />
                <input type="text" name="region" placeholder="Region" value={formData.region} onChange={handleChange} />
                <input type="text" name="contact" placeholder="Contact" value={formData.contact} onChange={handleChange} />
                <input type="text" name="contact_2" placeholder="Secondary Contact" value={formData.contact_2} onChange={handleChange} />
                <input type="text" name="previous_due" placeholder="Previous Due" value={formData.previous_due} onChange={handleChange} />
                <input type="file" name="image" onChange={handleFileChange} />
                <button type="submit">Save</button>
              </form>
            </div>
          )}

          {activePopup === 2 && (
            <div className="form-section">
              <h2 className="form-title">Saved Customers</h2>
              <input
                type="text"
                placeholder="Search by ID, Name, Region, or Phone"
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
              />
              <ul className="customer-list">
                {filteredCustomers.map((customer) => (
                  <li key={customer.id} onClick={() => handleCustomerClick(customer)}>
                    {`${customer.business_name} - ${customer.name} - ${customer.region} - ${customer.contact}`}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {activePopup === 11 && selectedCustomer && (
            <div className="customer-profile">
              <h2 className="form-title">Mr. {selectedCustomer.name}</h2>
              {selectedCustomer.image && (
                <img src={`http://pos.ujjalflourmills.com//${selectedCustomer.image}`} alt="Customer" className="customer-image" />
              )}
              <ul className="customer-details-list">
                <li>Business Name: {selectedCustomer.business_name}</li>
                <li>Address: {selectedCustomer.address}</li>
                <li>Region: {selectedCustomer.region}</li>
                <li>Contact: {selectedCustomer.contact}</li>
                <li>Secondary Contact: {selectedCustomer.contact_2}</li>
                <li>Previous Due: {selectedCustomer.previous_due}</li>
              </ul>
              <button className="back-button" onClick={() => handlePopup(2)}>Back to List</button>
            </div>
          )}

          {activePopup === 3 && (
            <div className="form-section">
              <h2 className="form-title">Add New Product</h2>
              <form onSubmit={handleProductSubmit}>
                <input type="text" name="productName" placeholder="Product Name" value={productData.productName} onChange={handleProductChange} />
                <input type="text" name="productType" placeholder="Product Type" value={productData.productType} onChange={handleProductChange} />
                <textarea name="remarks" placeholder="Input Remarks" value={productData.remarks} onChange={handleProductChange} />
                <input type="file" name="image" onChange={handleProductFileChange} />
                <button type="submit">Save</button>
              </form>
            </div>
          )}

          {activePopup === 4 && (
            <div className="form-section">
              <h2 className="form-title">Saved Products</h2>
              <ul className="customer-list">
                {products.map((product) => (
                  <li key={product.id} onClick={() => handleProductClick(product)}>
                    {`${product.product_name} - ${product.product_type}`}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {activePopup === 12 && selectedProduct && (
            <div className="product-profile">
              <h2 className="form-title">Update Product</h2>
              {selectedProduct.image && (
                <img src={`http://pos.ujjalflourmills.com//${selectedProduct.image}`} alt="Product" className="product-image" />
              )}
              <ul className="product-details-list">
                <li>Product Name: {selectedProduct.product_name}</li>
                <li>Product Type: {selectedProduct.product_type}</li>
                <li>Remarks: {selectedProduct.remarks}</li>
                <li>Current Price: {selectedProduct.price}</li>
              </ul>
              <input
                type="number"
                name="price"
                placeholder="Update Price"
                value={selectedProduct.price}
                onChange={(e) => setSelectedProduct({ ...selectedProduct, price: e.target.value })}
              />
              <button onClick={handlePriceUpdate}>Update Price</button>
              <button className="back-button" onClick={() => handlePopup(4)}>Back to List</button>
            </div>
          )}
        </div>
      )}

      {message && <div className="message-popup">{message}</div>}
    </div>
  );
};

export default AdminDashboard;
