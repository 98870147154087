import React from 'react';

const LivePreview = ({ posData }) => {
    const subtotal = posData.products.reduce((sum, product) => {
        return sum + product.quantity * product.unitPrice;
    }, 0);

    return (
        <div className="live-preview">
            <h3>Cart</h3>
            <div className="order-preview">
                {posData.products.map((product, index) => (
                    <div key={index}>
                        <p>{product.productName} ({product.productType}) - {product.quantity} @ Tk. {product.unitPrice}</p>
                    </div>
                ))}
                <p><strong>Subtotal:</strong> Tk. {subtotal.toFixed(2)}</p>
            </div>
        </div>
    );
};

export default LivePreview;
