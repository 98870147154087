import React, { useState } from 'react';
import './App.css';
import jsPDF from 'jspdf';
import CustomerDetails from './components/CustomerDetails';
import OrderDetails from './components/OrderDetails';
import PostOrderDetails from './components/PostOrderDetails';
import LivePreview from './components/LivePreview';

const POSPage = () => {
    const [posData, setPosData] = useState({
        customerName: '',
        phoneNumber: '',
        deliveryAddress: '',
        products: [],
        currentProduct: { productName: '', productType: '', quantity: '', unitPrice: '' },
        advancePayment: '',
        previousDue: '',
        deliveryDate: ''
    });

    const [orderFinished, setOrderFinished] = useState(false);

    const handlePosChange = (e) => {
        const { name, value } = e.target;
        setPosData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleProductChange = (e) => {
        const { name, value } = e.target;
        setPosData((prevData) => ({
            ...prevData,
            currentProduct: {
                ...prevData.currentProduct,
                [name]: value
            }
        }));
    };

    const addProduct = () => {
        if (
            posData.currentProduct.productName &&
            posData.currentProduct.productType &&
            posData.currentProduct.quantity &&
            posData.currentProduct.unitPrice
        ) {
            setPosData((prevData) => ({
                ...prevData,
                products: [...prevData.products, prevData.currentProduct],
                currentProduct: { productName: '', productType: '', quantity: '', unitPrice: '' }
            }));
        }
    };

    const modifyProduct = (index) => {
        const productToModify = posData.products[index];
        const remainingProducts = posData.products.filter((_, i) => i !== index);
        setPosData({
            ...posData,
            products: remainingProducts,
            currentProduct: productToModify
        });
    };

    const finishOrder = () => {
        setOrderFinished(true);
    };

    const handlePrintPDF = () => {
        if (!orderFinished) return;

        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [80, 200]
        });

        const totalOrderValue = posData.products.reduce(
            (total, product) => total + product.quantity * product.unitPrice,
            0
        );
        const totalReceivable = totalOrderValue + parseFloat(posData.previousDue || 0);
        const remainingDue = totalReceivable - parseFloat(posData.advancePayment || 0);

        doc.setFontSize(12);
        doc.setFont('courier', 'bold');
        doc.text('Ujjal Flour Mills', 40, 10, null, null, 'center');

        doc.setFontSize(7);
        doc.setFont('courier', 'normal');
        doc.text('Noraibag, Demra, Dhaka', 40, 14, null, null, 'center');
        doc.text('Phone: 01677529443', 40, 18, null, null, 'center');

        doc.text('------------------------------', 40, 22, null, null, 'center');

        doc.setFontSize(7);
        doc.text(`Mr. ${posData.customerName}`, 5, 28);
        doc.text(`Order Number: ${new Date().toISOString().replace(/[-:.]/g, '')}`, 5, 32);

        doc.text('------------------------------', 40, 36, null, null, 'center');

        // Adding headers for the three columns
        doc.setFont('courier', 'bold');
        doc.text('Item (Type) - Qty', 5, 42);
        doc.text('Unit Price', 48, 42, null, null, 'center');
        doc.text('Total', 75, 42, null, null, 'right');

        let adjustedY = 50;
        posData.products.forEach((product) => {
            const itemTotal = product.quantity * product.unitPrice;
            doc.setFont('courier', 'normal');

            // Ensure text in columns is well spaced and does not overlap
            doc.text(`${product.productName} (${product.productType})`, 5, adjustedY);
            adjustedY += 4;
            doc.text(`${product.quantity} piece`, 5, adjustedY); // Quantity in description format
            doc.text(`${product.unitPrice}`, 48, adjustedY, null, null, 'center'); // Adjusted column position
            doc.text(`${itemTotal.toFixed(2)}`, 75, adjustedY, null, null, 'right');
            adjustedY += 8;
        });

        // Post-Order Calculations
        adjustedY += 6;
        doc.setFont('courier', 'bold');
        doc.text('Total Order Value:', 5, adjustedY);
        doc.text(`${totalOrderValue.toFixed(2)}`, 75, adjustedY, null, null, 'right');

        adjustedY += 6;
        doc.setFont('courier', 'normal');
        doc.text('Previous Due:', 5, adjustedY);
        doc.text(`${parseFloat(posData.previousDue || 0).toFixed(2)}`, 75, adjustedY, null, null, 'right');

        // Add dashed line
        adjustedY += 6;
        doc.setDrawColor(0);
        doc.setLineDash([2, 1], 0); // Dashed line
        doc.line(5, adjustedY, 75, adjustedY);

        // Display Total Due
        adjustedY += 6;
        doc.setFont('courier', 'bold');
        doc.text('Total Due:', 5, adjustedY);
        doc.text(`${totalReceivable.toFixed(2)}`, 75, adjustedY, null, null, 'right');

        // Advance Paid
        adjustedY += 6;
        doc.setFont('courier', 'normal');
        doc.text('Advance Paid:', 5, adjustedY);
        doc.text(`${parseFloat(posData.advancePayment || 0).toFixed(2)}`, 75, adjustedY, null, null, 'right');

        // Add another dashed line
        adjustedY += 6;
        doc.line(5, adjustedY, 75, adjustedY);

        // Final Total Calculated Due
        adjustedY += 6;
        doc.setFont('courier', 'bold');
        doc.text('Final Total Due:', 5, adjustedY);
        doc.text(`${remainingDue.toFixed(2)}`, 75, adjustedY, null, null, 'right');

        // Add two lines of spacing
        adjustedY += 12;

        // Estimated Delivery Date
        doc.setFont('courier', 'normal');
        doc.text('Estimated Delivery Date:', 5, adjustedY);
        doc.text(`${posData.deliveryDate}`, 75, adjustedY, null, null, 'right');

        // Add two lines of spacing
        adjustedY += 12;

        // Space and bar for signature
        doc.text('Signature:', 55, adjustedY);
        doc.line(60, adjustedY + 1, 75, adjustedY + 1);

        // Footer at the bottom of the page
        const pageHeight = doc.internal.pageSize.height;
        doc.setFontSize(6);
        doc.setFont('courier', 'italic');
        doc.text('Thank you for your purchase!', 40, pageHeight - 10, null, null, 'center');
        doc.text('May you have a blessed day!', 40, pageHeight - 6, null, null, 'center');

        doc.save('receipt.pdf');
    };

    return (
        <div className="app-container">
            <header className="header">
                <div className="header-content">
                    <div className="header-title">Ujjal Flour Mills</div>
                    <div className="header-address">Noraibag, Demra, Dhaka</div>
                </div>
            </header>
            <div className="tab-container">
                <div className="tabs">
                    <button className="tab active-tab">POS</button>
                </div>
            </div>
            <div className="content">
                <div className="tab-content">
                    <div className="form-container">
                        <CustomerDetails posData={posData} handlePosChange={handlePosChange} />
                        <OrderDetails
                            posData={posData}
                            handleProductChange={handleProductChange}
                            addProduct={addProduct}
                            modifyProduct={modifyProduct}
                        />
                        <PostOrderDetails posData={posData} handlePosChange={handlePosChange} />
                    </div>
                    <LivePreview posData={posData} />
                    <button type="button" onClick={finishOrder}>
                        Finish Order
                    </button>
                    <button type="button" onClick={handlePrintPDF} disabled={!orderFinished}>
                        Print Receipt
                    </button>
                </div>
            </div>
        </div>
    );
};

export default POSPage;
