import React from 'react';

const OrderDetails = ({ posData, handleProductChange, addProduct, modifyProduct }) => {
    return (
        <div className="form-section">
            <h3>Order Details</h3>
            <form>
                <label>
                    Product Name:
                    <input
                        type="text"
                        name="productName"
                        value={posData.currentProduct.productName}
                        onChange={handleProductChange}
                    />
                </label>
                <label>
                    Product Type:
                    <input
                        type="text"
                        name="productType"
                        value={posData.currentProduct.productType}
                        onChange={handleProductChange}
                    />
                </label>
                <label>
                    Quantity:
                    <input
                        type="number"
                        name="quantity"
                        value={posData.currentProduct.quantity}
                        onChange={handleProductChange}
                    />
                </label>
                <label>
                    Unit Price:
                    <input
                        type="number"
                        name="unitPrice"
                        value={posData.currentProduct.unitPrice}
                        onChange={handleProductChange}
                    />
                </label>
                <button type="button" onClick={addProduct}>
                    Add Another Item
                </button>
            </form>
            <div>
                {posData.products.map((product, index) => (
                    <button key={index} onClick={() => modifyProduct(index)}>
                        Modify {product.productName}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default OrderDetails;
