import React from 'react';

const PostOrderDetails = ({ posData, handlePosChange }) => {
    return (
        <div className="form-section">
            <h3>Post Order Details</h3>
            <form>
                <label>
                    Previous Due:
                    <input
                        type="number"
                        name="previousDue"
                        value={posData.previousDue}
                        onChange={handlePosChange}
                    />
                </label>
                <label>
                    Advance Payment:
                    <input
                        type="number"
                        name="advancePayment"
                        value={posData.advancePayment}
                        onChange={handlePosChange}
                    />
                </label>
                <label>
                    Delivery Date:
                    <input
                        type="date"
                        name="deliveryDate"
                        value={posData.deliveryDate}
                        onChange={handlePosChange}
                    />
                </label>
            </form>
        </div>
    );
};

export default PostOrderDetails;
